<template>
  <BasePanel :title="title">
    <div class="grid-wrapper" style="margin-top: 42px">
      <Grid
        style="height: 309px"
        :columns="columns"
        :data-items="ExceptionLogData"
      />
    </div>
    <div class="btn-wrapper">
      <BaseButton class="btn-primary-600" style="width: 158px">
        查看更多
        <router-link
          :to="{ name: 'ExceptionEventLog' }"
          class="fill-parent"
        ></router-link>
      </BaseButton>
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue'
import BasePanel from './BasePanel.vue'
import { Grid } from '@progress/kendo-vue-grid'
import BaseButton from '@/components/Button/BaseButton.vue'
import Ip from '@/components/Grid/Cell/DashboardPage/Ip.vue'
import IpHeader from '@/components/Grid/Cell/DashboardPage/IpHeader.vue'
import Mac from '@/components/Grid/Cell/DashboardPage/Mac.vue'
import MacHeader from '@/components/Grid/Cell/DashboardPage/MacHeader.vue'

export default defineComponent({
  components: {
    BasePanel,
    Grid,
    BaseButton
  },
  setup() {
    const title = '最新 10 筆異常事件'
    const columns = [
      {
        field: 'EventTime',
        title: '事件時間',
        width: '155px'
      },
      { field: 'EventType', title: '事件類型' },
      {
        field: 'Ip',
        title: 'IP',
        width: '45px',
        cell: markRaw(Ip),
        headerCell: markRaw(IpHeader)
      },
      {
        field: 'Mac',
        title: 'MAC',
        width: '60px',
        cell: markRaw(Mac),
        headerCell: markRaw(MacHeader)
      }
    ]
    const ExceptionLogData = [...Array(10)].map(() => ({
      EventTime: '2020/01/02 11:59:44',
      EventType: '[登入/登出] 管理員登出',
      Ip: '192.168.81.175',
      Mac: '9C5C8EAFB438'
    }))

    return {
      title,
      columns,
      ExceptionLogData
    }
  }
})
</script>

<style scoped></style>
