<template>
  <td class="ipv4-rate-cell">
    <div class="rate-bar" :style="{ width: `${dataItem.Ipv4Rate}%` }"></div>
    <span class="rate-text">{{ dataItem.Ipv4Rate }}%</span>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss">
.ipv4-rate-cell {
  padding: 9px 8px 9px 0;
  position: relative;
}
</style>
