<template>
  <BasePanel :title="title" :half="true">
    <div class="status-list">
      <StatusItem
        v-for="item in networkStatusList"
        :key="item.id"
        :item="item"
      />
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BasePanel from './BasePanel.vue'
import StatusItem from './StatusItem.vue'

export default defineComponent({
  components: {
    BasePanel,
    StatusItem
  },
  setup() {
    const title = '網路狀態'
    const networkStatusList = [
      {
        id: 0,
        name: '使用中的 IPv4',
        iconName: 'ip-use',
        count: 15,
        unit: '',
        href: '/'
      },
      {
        id: 1,
        name: '未使用的 IPv4',
        iconName: 'ip-unuse',
        count: 2533,
        unit: '',
        href: '/'
      },
      {
        id: 2,
        name: '上線中的 MAC',
        iconName: 'mac',
        count: 14,
        unit: '',
        href: '/'
      },
      {
        id: 3,
        name: '等待覆核的設備',
        iconName: 'check-list',
        count: 0,
        unit: '',
        href: '/'
      },
      {
        id: 4,
        name: '使用中的 IPv6',
        iconName: 'ip-use',
        count: 12,
        unit: '',
        href: '/'
      },
      {
        id: 5,
        name: 'Dual Stack MAC',
        iconName: 'mac-dual',
        count: 10,
        unit: '',
        href: '/'
      }
    ]
    return {
      title,
      networkStatusList
    }
  }
})
</script>

<style scoped></style>
