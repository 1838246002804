<template>
  <BasePanel :title="title">
    <div class="grid-wrapper" style="margin-top: 42px">
      <Grid
        style="height: 309px"
        :columns="columns"
        :data-items="AuditLogData"
      />
    </div>
    <div class="btn-wrapper">
      <BaseButton class="btn-primary-600" style="width: 158px">
        查看更多
        <router-link
          :to="{ name: 'AuditLog' }"
          class="fill-parent"
        ></router-link>
      </BaseButton>
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue'
import BasePanel from './BasePanel.vue'
import { Grid } from '@progress/kendo-vue-grid'
import Username from '@/components/Grid/Cell/DashboardPage/UserName.vue'
import UsernameHeader from '@/components/Grid/Cell/DashboardPage/UsernameHeader.vue'
import EventTime from '@/components/Grid/Cell/DashboardPage/EventTime.vue'
import BaseButton from '@/components/Button/BaseButton.vue'

export default defineComponent({
  components: {
    BasePanel,
    Grid,
    BaseButton
  },
  setup() {
    const title = '最新 10 筆管理日誌'
    const columns = [
      {
        field: 'EventTime',
        title: '事件時間',
        width: '155px',
        cell: markRaw(EventTime)
      },
      { field: 'EventType', title: '事件類型' },
      {
        field: 'Username',
        title: '帳號',
        width: '129px',
        cell: markRaw(Username),
        headerCell: markRaw(UsernameHeader)
      }
    ]
    const AuditLogData = [...Array(10)].map(() => ({
      EventTime: '2020/01/02 11:59:44',
      EventType: '[登入/登出] 管理員登出',
      Username: 'Tommy'
    }))

    return {
      title,
      columns,
      AuditLogData
    }
  }
})
</script>

<style scoped></style>
