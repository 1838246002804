
import { defineComponent, ref } from 'vue'
import TopEventsCard from '@/components/Card/TopEventsCard.vue'
import NetworkStatusPanel from '@/components/Panel/NetworkStatusPanel.vue'
import SystemStatusPenel from '@/components/Panel/SystemStatusPenel.vue'
import DayEventsPanel from '@/components/Panel/DayEventsPanel.vue'
import Ipv4StackingPanel from '@/components/Panel/Ipv4StackingPanel.vue'
import Ipv6StackingPanel from '@/components/Panel/Ipv6StackingPanel.vue'
import AuditLogPanel from '@/components/Panel/AuditLogPanel.vue'
import ExceptionEventsPanel from '@/components/Panel/ExceptionEventsPanel.vue'
import Draggable from 'vuedraggable'
import useDraggable from '@/composable/useDraggable'

export default defineComponent({
  components: {
    TopEventsCard,
    NetworkStatusPanel,
    SystemStatusPenel,
    DayEventsPanel,
    Ipv4StackingPanel,
    Ipv6StackingPanel,
    AuditLogPanel,
    ExceptionEventsPanel,
    Draggable
  },
  setup() {
    // FIXME: 待加入 fetch topEvents api
    const topEvents = [
      {
        id: 0,
        title: 'IP 衝突開始 (超出的變成..).',
        count: 2,
        importance: 'high', // or "normal" (重要程度)
        href: 'IpConflict'
      },
      {
        id: 1,
        title: '未知的 IP 派發來源',
        count: 2,
        importance: 'high',
        href: ''
      },
      {
        id: 2,
        title: 'IPv4 廣播超量',
        count: 0,
        importance: 'normal',
        href: 'Ipv4Broadcast'
      },
      {
        id: 3,
        title: 'IPv6 群播超量',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 4,
        title: '未授權的主機',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 5,
        title: '重要 IP 離線 ',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 6,
        title: '超出管理範圍',
        count: 0,
        importance: 'normal',
        href: ''
      }
    ]

    // Darggable 相關
    const {
      drag,
      isDargEnable,
      list,
      enableDrag,
      disableDrag,
      sort
    } = useDraggable()
    // Right Handle 右方控制鈕
    const isRightHandleOpen = ref(false)
    const rightHandleClick = () => {
      if (isRightHandleOpen.value) disableDrag() // 離開拖曳模式
      // toggle 控制鈕
      isRightHandleOpen.value = !isRightHandleOpen.value
    }
    const save = () => {
      disableDrag()
      isRightHandleOpen.value = false
    }

    return {
      topEvents,
      drag,
      isDargEnable,
      list,
      enableDrag,
      disableDrag,
      sort,
      isRightHandleOpen,
      rightHandleClick,
      save
    }
  }
})
