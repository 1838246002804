<template>
  <td class="event-time-cell">
    <span class="event-date">{{ date }}&nbsp;</span>
    <span>{{ time }}</span>
  </td>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup(props) {
    //@ts-ignore
    const date = computed(() => props.dataItem.EventTime.split(' ')[0])
    //@ts-ignore
    const time = computed(() => props.dataItem.EventTime.split(' ')[1])
    return {
      date,
      time
    }
  }
})
</script>

<style scoped lang="scss"></style>
