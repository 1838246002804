<template>
  <BasePanel :title="title" :half="true">
    <div class="status-list">
      <StatusItem
        v-for="item in systemStatusList"
        :key="item.id"
        :item="item"
      />
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BasePanel from './BasePanel.vue'
import StatusItem from './StatusItem.vue'

export default defineComponent({
  components: {
    BasePanel,
    StatusItem
  },
  setup() {
    const title = '系統狀態'
    const systemStatusList = [
      {
        id: 0,
        name: 'Probe 連線狀態',
        iconName: 'probe',
        count: '2/2',
        unit: '',
        href: '/'
      },
      {
        id: 1,
        name: 'Server 授權數量',
        iconName: 'server',
        count: '15/10000',
        unit: '',
        href: '/'
      },
      {
        id: 2,
        name: 'PIXIS 服務',
        iconName: 'pixis',
        count: 'ON',
        unit: '',
        href: '/'
      },
      {
        id: 3,
        name: 'DB 容量',
        iconName: 'db-space',
        count: 671.94,
        unit: 'MB',
        href: '/'
      },
      {
        id: 4,
        name: '伺服器剩餘空間',
        iconName: 'server-space',
        count: 57.39,
        unit: 'GB',
        href: '/'
      },
      {
        id: 5,
        name: 'Switch 回應數量',
        iconName: 'switch',
        count: 11,
        unit: '',
        href: '/'
      }
    ]
    return {
      title,
      systemStatusList
    }
  }
})
</script>

<style scoped></style>
