
import { defineComponent } from 'vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import useTooltip from '@/composable/useTooltip'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { showTooltip, onSuccess, onError } = useTooltip()

    return {
      showTooltip,
      onSuccess,
      onError
    }
  }
})
