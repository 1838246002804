<template>
  <BasePanel :title="title">
    <div class="dropdown-wrapper">
      <DropdownList
        :items="offices"
        :defaultValue="'Taipei Office'"
        @change="handleChange"
      />
    </div>
    <div class="grid-wrapper" style="margin-top: 5.4px">
      <Grid
        style="height: 356px"
        :columns="columns"
        :data-items="ipv4StackingData"
      />
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue'
import BasePanel from './BasePanel.vue'
import DropdownList from '@/components/Dropdown/DropdownList.vue'
import { Grid } from '@progress/kendo-vue-grid'
import Rank from '@/components/Grid/Cell/DashboardPage/Rank.vue'
import DhcpTreeName from '@/components/Grid/Cell/DashboardPage/DhcpTreeName.vue'
import Ipv4Rate from '@/components/Grid/Cell/DashboardPage/Ipv4Rate.vue'

export default defineComponent({
  components: {
    BasePanel,
    DropdownList,
    Grid
  },
  setup() {
    const title = '前 10 筆 IPv4 DHCP 使用率'
    const offices = ['Taipei Office', 'Tommy Office', 'San Jose Office Office']

    // 接收 DropdownList.vue 發出的 custom event
    const handleChange = item => {
      console.log('DropdownList has changed: ' + item)
      // 可以做相應的事，例如打 API
    }

    // Grid 相關
    const columns = [
      { field: 'Rank', title: '排名', width: '54px', cell: markRaw(Rank) },
      {
        field: 'DhcpTreeName',
        title: '網段名稱',
        width: '162px',
        cell: markRaw(DhcpTreeName)
      },
      { field: 'Ipv4Rate', title: '派發 IP 比率', cell: markRaw(Ipv4Rate) }
    ]
    const ipv4StackingData = [
      { Rank: 1, DhcpTreeName: '業務課', Ipv4Rate: 100, href: '/host' },
      { Rank: 2, DhcpTreeName: 'Pool 1', Ipv4Rate: 90, href: '/host' },
      { Rank: 3, DhcpTreeName: '資訊科', Ipv4Rate: 40, href: '/host' },
      { Rank: 4, DhcpTreeName: 'VLAN1', Ipv4Rate: 20, href: '/host' },
      { Rank: 5, DhcpTreeName: 'DF-OA-A3F', Ipv4Rate: 12, href: '/host' },
      { Rank: 6, DhcpTreeName: 'SODLKSDD', Ipv4Rate: 12, href: '/host' },
      { Rank: 7, DhcpTreeName: '192.168.2.x', Ipv4Rate: 10, href: '/host' },
      { Rank: 8, DhcpTreeName: '192.168.86.X', Ipv4Rate: 5, href: '/host' },
      { Rank: 9, DhcpTreeName: '192.168.24.x', Ipv4Rate: 5, href: '/host' },
      { Rank: 10, DhcpTreeName: '192.168.21.x', Ipv4Rate: 0, href: '/host' }
    ]

    return {
      title,
      offices,
      handleChange,
      columns,
      ipv4StackingData
    }
  }
})
</script>

<style scoped></style>
