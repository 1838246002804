<template>
  <div class="ip-cell-header">{{ title }}</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    title: String,
    sortable: [Boolean, Object]
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss">
.ip-cell-header {
  text-align: center;
}
</style>
