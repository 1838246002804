<template>
  <div class="status-list-item">
    <SvgIconFixedColor class="status-icon" :icon="item.iconName" />
    <div class="status-infos">
      <div class="status-name">{{ item.name }}</div>
      <span class="status-count">{{ item.count }}</span
      ><span class="status-unit">&nbsp;{{ item.unit }}</span>
    </div>
    <SvgIconFixedColor class="status-arrow" icon="arrow-right-in-circle" />
    <router-link :to="item.href" class="status-link"></router-link>
  </div>
  <div class="divider"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
