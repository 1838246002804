<template>
  <BasePanel :title="title">
    <div class="events-chart" ref="chartElement"></div>
    <div class="legend-list">
      <BaseCheckbox
        class="legend-list-item"
        v-model="legend.checked"
        v-for="legend in legendList"
        :key="legend.id"
        @change="toggleSelect(legend.name)"
      >
        <div class="legend-name">{{ legend.name }}</div>
        <div
          class="legend-tag"
          :style="{ backgroundColor: legend.color }"
        ></div>
      </BaseCheckbox>
    </div>
  </BasePanel>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, reactive } from 'vue'
import BasePanel from './BasePanel.vue'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import useECharts from '@/composable/useECharts'
import elementResizeDetectorMaker from 'element-resize-detector'

export default defineComponent({
  components: {
    BasePanel,
    BaseCheckbox
  },
  setup() {
    // CHECK: (https://echarts.apache.org/zh/tutorial.html)
    // CHECK: (https://www.npmjs.com/package/element-resize-detector)

    const title = '24 小時事件'
    // 圖表相關
    const legendList = reactive([
      {
        id: 0,
        name: '系統事件',
        checked: true,
        color: '#355DFF'
      },
      {
        id: 1,
        name: '管理日誌',
        checked: true,
        color: '#3DCB97'
      },
      {
        id: 2,
        name: '異常事件',
        checked: true,
        color: '#FCCB36'
      },
      {
        id: 3,
        name: '主機事件',
        checked: false,
        color: '#B7C0EB'
      },
      {
        id: 4,
        name: '封鎖事件',
        checked: true,
        color: '#E85153'
      },
      {
        id: 5,
        name: '授權事件',
        checked: false,
        color: '#6C5DD3'
      }
    ])
    let chartInstanse
    const { chartElement, draw } = useECharts(legendList)

    // 切換折線圖
    const toggleSelect = legendName => {
      console.log('===', legendName, 'legendToggleSelect ===')
      if (chartInstanse) {
        // @ts-ignore
        chartInstanse.dispatchAction({
          type: 'legendToggleSelect',
          name: legendName
        })
      }
    }

    // Resize 相關
    const erd = elementResizeDetectorMaker()
    const resizeListener = () => chartInstanse.resize()

    onMounted(() => {
      // 開始繪圖
      chartInstanse = draw()
      // onMount 3000ms 後，開始監聽 chartElement 寬度變化
      setTimeout(() => {
        erd.listenTo(chartElement.value, resizeListener)
      }, 3000)
    })

    onBeforeUnmount(() => {
      // 移除監聽器
      erd.removeListener(chartElement.value, resizeListener)
    })

    return {
      title,
      legendList,
      toggleSelect,
      chartElement
    }
  }
})
</script>

<style scoped></style>
