import { ref, computed } from 'vue'
import { useStore } from 'vuex'

const useDraggable = () => {
  // vuex store
  const store = useStore()

  // 狀態：
  // 拖曳中
  const drag = ref(false)
  // 拖曳模式
  const isDargEnable = computed(() => store.state.drag.isDargEnable)
  // 拖曳列表
  const list = computed({
    // CHECK: (https://github.com/SortableJS/Vue.Draggable#with-vuex)
    get: () => store.state.drag.list,
    set: newList => {
      store.commit('drag/UPDATE_LIST', newList)
    }
  })

  // 操作：
  // 啟用拖曳模式
  const enableDrag = () => store.commit('drag/ENABLE_DRAG')
  // 鎖定拖曳模式
  const disableDrag = () => store.commit('drag/DISABLE_DRAG')
  // 還原排序
  const sort = () => {
    list.value = list.value.sort((a, b) => a.order - b.order)
  }

  return {
    drag,
    isDargEnable,
    list,
    enableDrag,
    disableDrag,
    sort
  }
}

export default useDraggable
