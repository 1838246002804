<template>
  <div class="base-panel" :class="{ 'base-panel-half': half }">
    <!-- 標題 -->
    <h5 class="base-panel-title">{{ title }}</h5>
    <!-- 內容 -->
    <slot />
    <!-- 拖曳遮罩 -->
    <PanelDragLayer :title="title" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PanelDragLayer from './PanelDragLayer.vue'

export default defineComponent({
  components: {
    PanelDragLayer
  },
  props: {
    title: String,
    half: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
})
</script>
