import { ref } from 'vue'

const useTooltip = () => {
  const showTooltip = ref(false)

  const onSuccess = value => alert('已複製 ' + value)
  const onError = () => alert('複製失敗')

  return {
    showTooltip,
    onSuccess,
    onError
  }
}

export default useTooltip
