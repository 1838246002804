<template>
  <div
    style="height: 1000px"
    class="page-dashboard"
    :class="{ 'is-drag-enable': isDargEnable }"
  >
    <div class="card-container">
      <!-- 上方紅、綠卡牌 -->
      <TopEventsCard
        v-for="event in topEvents"
        :key="event.id"
        :event="event"
      />
    </div>
    <!-- 整個 Panel 區域，包在 Draggable 元件裡 -->
    <Draggable
      class="panel-container"
      v-model="list"
      :disabled="!isDargEnable"
      item-key="order"
      animation="250"
      ghostClass="ghost"
      @start="drag = true"
      @end="drag = false"
    >
      <template #item="{ element }">
        <component :is="element.componentName"></component>
      </template>
    </Draggable>
    <!-- 右方控制項：啟動、關閉拖曳模式，還原排序 -->
    <div
      class="right-handle"
      :class="{ 'is-open': isRightHandleOpen, 'is-drag-enable': isDargEnable }"
    >
      <div class="right-handle-btn" @click="rightHandleClick">
        <SvgIcon icon="setup" v-if="!isRightHandleOpen" />
        <SvgIcon icon="cross" v-else />
      </div>
      <div
        class="drag-control-btn drag-start-btn"
        v-if="!isDargEnable"
        @click="enableDrag"
      >
        排序儀表板
      </div>
      <div
        class="btn btn-primary drag-control-btn drag-end-btn"
        v-if="isDargEnable"
        @click="save"
      >
        儲存
      </div>
      <div
        class="drag-control-btn drag-sort-btn"
        v-if="isDargEnable"
        @click="sort"
      >
        還原排序
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import TopEventsCard from '@/components/Card/TopEventsCard.vue'
import NetworkStatusPanel from '@/components/Panel/NetworkStatusPanel.vue'
import SystemStatusPenel from '@/components/Panel/SystemStatusPenel.vue'
import DayEventsPanel from '@/components/Panel/DayEventsPanel.vue'
import Ipv4StackingPanel from '@/components/Panel/Ipv4StackingPanel.vue'
import Ipv6StackingPanel from '@/components/Panel/Ipv6StackingPanel.vue'
import AuditLogPanel from '@/components/Panel/AuditLogPanel.vue'
import ExceptionEventsPanel from '@/components/Panel/ExceptionEventsPanel.vue'
import Draggable from 'vuedraggable'
import useDraggable from '@/composable/useDraggable'

export default defineComponent({
  components: {
    TopEventsCard,
    NetworkStatusPanel,
    SystemStatusPenel,
    DayEventsPanel,
    Ipv4StackingPanel,
    Ipv6StackingPanel,
    AuditLogPanel,
    ExceptionEventsPanel,
    Draggable
  },
  setup() {
    // FIXME: 待加入 fetch topEvents api
    const topEvents = [
      {
        id: 0,
        title: 'IP 衝突開始 (超出的變成..).',
        count: 2,
        importance: 'high', // or "normal" (重要程度)
        href: 'IpConflict'
      },
      {
        id: 1,
        title: '未知的 IP 派發來源',
        count: 2,
        importance: 'high',
        href: ''
      },
      {
        id: 2,
        title: 'IPv4 廣播超量',
        count: 0,
        importance: 'normal',
        href: 'Ipv4Broadcast'
      },
      {
        id: 3,
        title: 'IPv6 群播超量',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 4,
        title: '未授權的主機',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 5,
        title: '重要 IP 離線 ',
        count: 0,
        importance: 'normal',
        href: ''
      },
      {
        id: 6,
        title: '超出管理範圍',
        count: 0,
        importance: 'normal',
        href: ''
      }
    ]

    // Darggable 相關
    const {
      drag,
      isDargEnable,
      list,
      enableDrag,
      disableDrag,
      sort
    } = useDraggable()
    // Right Handle 右方控制鈕
    const isRightHandleOpen = ref(false)
    const rightHandleClick = () => {
      if (isRightHandleOpen.value) disableDrag() // 離開拖曳模式
      // toggle 控制鈕
      isRightHandleOpen.value = !isRightHandleOpen.value
    }
    const save = () => {
      disableDrag()
      isRightHandleOpen.value = false
    }

    return {
      topEvents,
      drag,
      isDargEnable,
      list,
      enableDrag,
      disableDrag,
      sort,
      isRightHandleOpen,
      rightHandleClick,
      save
    }
  }
})
</script>

<style scoped></style>
