
import { defineComponent, markRaw } from 'vue'
import BasePanel from './BasePanel.vue'
import DropdownList from '@/components/Dropdown/DropdownList.vue'
import { Grid } from '@progress/kendo-vue-grid'
import Rank from '@/components/Grid/Cell/DashboardPage/Rank.vue'
import DhcpTreeName from '@/components/Grid/Cell/DashboardPage/DhcpTreeName.vue'
import Ipv6UseAmount from '@/components/Grid/Cell/DashboardPage/Ipv6UseAmount.vue'
import Ipv6UseAmountHeader from '@/components/Grid/Cell/DashboardPage/Ipv6UseAmountHeader.vue'
import Ipv6Range from '@/components/Grid/Cell/DashboardPage/Ipv6Range.vue'

export default defineComponent({
  components: {
    BasePanel,
    DropdownList,
    Grid
  },
  setup() {
    const title = '前 10 筆 IPv6 DHCP 使用率'
    const offices = ['Taipei Office', 'Tommy Office', 'San Jose Office Office']

    // 接收 DropdownList.vue 發出的 custom event
    const handleChange = item => {
      console.log('DropdownList has changed: ' + item)
      // 可以做相應的事，例如打 API
    }

    // Grid 相關
    const columns = [
      { field: 'Rank', title: '排名', width: '54px', cell: markRaw(Rank) },
      {
        field: 'DhcpTreeName',
        title: '網段名稱',
        width: '142px',
        cell: markRaw(DhcpTreeName)
      },
      {
        field: 'Ipv6UseAmount',
        title: '數量',
        cell: markRaw(Ipv6UseAmount),
        headerCell: markRaw(Ipv6UseAmountHeader)
      },
      {
        field: 'Ipv6Range',
        width: '212px',
        title: 'IPv6 管理範圍',
        cell: markRaw(Ipv6Range)
      }
    ]
    const ipv6StackingData = [
      {
        Rank: 1,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 100,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 2,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 90,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 3,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 40,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 4,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 20,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 5,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 12,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 6,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 12,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 7,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 10,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 8,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 5,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 9,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 5,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      },
      {
        Rank: 10,
        DhcpTreeName: 'sdsadass',
        Ipv6UseAmount: 0,
        href: '/host',
        Ipv6Range: '2001:b030:1228:1086::'
      }
    ]

    return {
      title,
      offices,
      handleChange,
      columns,
      ipv6StackingData
    }
  }
})
