<template>
  <div class="drag-layer">
    <h5 class="drag-title">{{ title }}</h5>
    <h5 class="drag-msg">拖曳至區塊位置</h5>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
