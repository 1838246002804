<template>
  <td class="dhcp-treename-cell">
    <router-link :to="dataItem.href" class="dhcp-link">
      {{ dataItem.DhcpTreeName }}
      <SvgIcon icon="dot-more" class="dot-icon" />
    </router-link>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
