<template>
  <td
    class="ip-cell"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
    v-clipboard="dataItem.Ip"
    v-clipboard:success="() => onSuccess('複製成功')"
    v-clipboard:error="onError"
  >
    <SvgIcon icon="host" class="ip-icon" />
  </td>

  <teleport to="#tooltip-container">
    <transition name="fade">
      <Tooltip
        v-if="showTooltip"
        :offsetX="-80"
        :offsetY="18"
        class="ip-mac-tooltip is-ip"
      >
        <p class="copy-text">點擊複製</p>
        <p class="ip-text">
          <span class="feild-name">IP</span>{{ dataItem.Ip }}
        </p>
        <p class="mac-text">
          <span class="feild-name">MAC</span>{{ dataItem.Mac }}
        </p>
      </Tooltip>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import useTooltip from '@/composable/useTooltip'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { showTooltip, onSuccess, onError } = useTooltip()

    return {
      showTooltip,
      onSuccess,
      onError
    }
  }
})
</script>

<style scoped>
.ip-cell {
  text-align: center;
}
</style>
