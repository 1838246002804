<template>
  <td
    class="ipv6-range-cell"
    @mouseenter="showTooltip = true"
    @mouseleave="showTooltip = false"
    v-clipboard="dataItem.Ipv6Range"
    v-clipboard:success="
      () => this.$toast.show('複製成功 ' + dataItem.Ipv6Range, 'success')
    "
    v-clipboard:error="onError"
  >
    <span class="content-wrapper">
      {{ dataItem.Ipv6Range }}
    </span>
    <SvgIcon icon="copy" class="copy-icon" />
  </td>

  <teleport to="#tooltip-container">
    <transition name="fade">
      <Tooltip v-if="showTooltip" :offsetX="20" :offsetY="-10">
        點擊複製
      </Tooltip>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import useTooltip from '@/composable/useTooltip'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { showTooltip, onSuccess, onError } = useTooltip()

    return {
      showTooltip,
      onSuccess,
      onError
    }
  }
})
</script>

<style scoped></style>
