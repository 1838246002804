
import { defineComponent } from 'vue'
import PanelDragLayer from './PanelDragLayer.vue'

export default defineComponent({
  components: {
    PanelDragLayer
  },
  props: {
    title: String,
    half: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {}
  }
})
